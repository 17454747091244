<template>
	<div>
		<myheader></myheader>
		<div class="banimg">
			<div class="banmain">
				<div class="title">
					<h3>写游记</h3>
					<p>
						 <el-radio v-model="form.type_id" label="1">图文</el-radio>
						 <el-radio v-model="form.type_id" label="2">视频</el-radio>
					</p>
				</div>
				<div class="inp">
					<input type="text" placeholder="请输入标题" v-model="form.title">

				</div>
			</div>
		</div>
		
		<div class="form">
			
			<div>
				<el-upload
				class="upload-demo"
				:action='baseURLs'
				:on-preview="handlePreview"
				:on-remove="handleRemove"
				:before-remove="beforeRemove"
				name="image"
				multiple
				:limit="1"
				:on-success="successvideo"
				:on-exceed="handleExceed"
				:file-list="fileList">
				<div class="video" v-if="form.type_id==2">
					<img src="../../assets/shot/video.png">
					<p>上传视频</p>
				</div>
				<!-- <div slot="tip" class="el-upload__tip" v-if="form.type_id==2">只能上传jpg/png文件，且不超过500kb</div> -->
				</el-upload>
			</div>
			<div >
				<p style="margin-bottom:10px">摘要</p>
				<input type="text" placeholder="请输入文章摘要" v-model="form.preview" class="zy">
			</div>
			
			<div style="margin-top:30px">
				<el-upload
				class="avatar-uploader"
				:action='baseURLs'
				:show-file-list="false"
				name="image"
				:on-success="handleAvatarSuccess"
				:before-upload="beforeAvatarUpload">
				<img v-if="form.attachment" :src="form.attachment" class="avatar">
				<i v-else class="el-icon-plus avatar-uploader-icon"></i>
				 <div slot="tip" class="el-upload__tip">封面图只能上传jpeg/png/jpg文件，宽高比约 2:1或16:9 的图片且不超过500kb</div>
				</el-upload>
			</div>
			<div class="editor">
                 <!-- <quill-editor v-model="form.content"
                 ref="myQuillEditor"  ></quill-editor> -->
				  <wueditor
					v-model="form.content"
					ref="myQuillEditor"
					style="height: 800px"
					></wueditor>
              </div>
			<!-- <textarea v-model="form.content"></textarea> -->
			<div class="but">
				<button style="background-color: #fff;" @click="up(0)" class="hand">保存草稿</button>
				<button style="background-color: #FF7C00;border:none;color: #fff;" @click="up(1)" class="hand">提交</button>
			</div>
			<p><span>*</span>提交后经平台审核通过，将在游记中展示</p>
		</div>

		<myfooter></myfooter>
		<myright></myright>
	</div>

</template>

<script>
import myheader from '@/components/header.vue'
import myfooter from '@/components/footer.vue'
import myright from '@/components/right.vue'
import {userArticleAddAPI} from "@/api/user"
import {takeViewAPI} from "@/api/trivialShot"
import wueditor from "@/components/wueditor";
import {baseURL} from "@/http/base"
export default {

  components: {
    myheader, myfooter, myright,wueditor
  },
  data () {
    return {
      radio: '1',
	  form:{
		  type_id:"1",
		  attachment:"",
		  title:'',
		  content:'',
		  preview:"",
		  video:"",
		  id:null,
		  status:null,
		  baseURLs:""
	  },
	  imageUrl: '',
	  fileList:[],
	  options: {
             
            placeholder: "请输入内容",
           modules: {
              toolbar: [

    			]
            },
          },
    }
  },
  created () {
	  this.baseURLs=baseURL+"index.php/index/file/upload"
	   let id=this.$route.query.id
	   if(id){
			this.getview(id)
	   }
	  
  },
  methods: {
	  //视频
	 handleRemove(file, fileList) {
        console.log(file, fileList);
      },
      handlePreview(file) {
        console.log(file);
      },
      handleExceed(files, fileList) {
        this.$message.warning(`当前限制选择 1 个视频文件`);
      },
      beforeRemove(file, fileList) {
		
		this.form.video=""
          this.$message({
            type: 'success',
            message: '删除成功!'
          });
		 
        
      },
	  successvideo(res){
 			console.log(res)
        this.form.video = res.data.file_url;
		console.log(this.fileList)
	  },
	//   图片
	  handleAvatarSuccess(res, file) {
		  console.log(res)
        this.form.attachment = res.data.file_url;
      },
       beforeAvatarUpload(file) {
		   const isJPG = file.type === 'image/jpeg' ||
                       file.type === 'image/jpg' ||
                        file.type === 'image/png'
				const isLt2M = file.size / 1024 / 1024 < 5;

				if (!isJPG) {
					this.$message.error('上传头像图片只能是 jpeg/png/jpg 格式!');
					return  false 
				}else  if (!isLt2M) {
					this.$message.error('上传头像图片大小不能超过 5MB!');
					return  false 
				}
		   return new Promise((resolve,reject) => {
				let reader = new FileReader()
				reader.readAsDataURL(file)
				reader.onload = () => {
					let img = new Image()
					img.src = reader.result
					img.onload = () => {
						if (
							((img.naturalWidth / img.naturalHeight) > 1.9 && (img.naturalWidth / img.naturalHeight)<2.1 ) ||
							((img.naturalWidth / img.naturalHeight) > 1.7 && (img.naturalWidth / img.naturalHeight)<1.82 )
						) {							
							return resolve(true)
						} else {
							this.$message.error(`当前图片宽${img.naturalWidth}px高${img.naturalHeight}px,请上传2:1(参考尺寸1000*500)或16:9(参考尺寸1920*1080)比例图片!`)
							return reject(false)
						}
					}
				}
			})
		
      },
	  up(status){
		  this.form.status=status
		  userArticleAddAPI(this.form).then(res=>{
			  if (this.form.status==1) {
				  this.$message("发布成功")
			  } else {
				  this.$message("保存成功")
			  }
			  this.form={
				type_id:"1",
				attachment:"",
				title:'',
				content:'',
				video:"",
				id:null,					
			}
			this.$router.push({ path: '/user/mytravel' })
		  })
	  },
	  getview(id){
		  takeViewAPI({id}).then(res=>{
			 this.form.attachment=res.View.attachment
			 this.form.title=res.View.title
			 this.form.content=res.View.content
			 this.form.id=res.View.id 
			 this.form.video=res.View.video 
			 this.fileList=[{name:res.View.video ,url:res.View.video }]
			 this.form.type_id=res.View.type_id +""
			 this.form.preview=res.View.preview
			 
		  })
	},
  }
}
</script>

<style lang="scss" scoped>

  .zy{
	    border: 1px solid #ccc;
	  line-height: 40px;
	  border-radius: 4px;
	  width: 100%;
	  padding: 0 10px;
  }
.banimg{
	padding-top: 65px;
	background: url(../../assets/shot/bg.png) no-repeat;
	height: 220px;
	width: 100%;
	min-width: 1200px;
	background-size: cover;
	.el-radio__input.is-checked .el-radio__inner{
		background-color: #FF7C00;
		border-color: #FF7C00;
	}
	.el-radio__input.is-checked+.el-radio__label{
		color: #333333;
	}
	.title{
		display: flex;
		align-items: center;
		width: 920px;
		margin:0 auto;
		h3{
			margin-right:50px;
			font-size: 22px;
			font-weight: 600;
			color: #6d380c;
			line-height: 30px;
		}
	}
	.inp{
		width: 920px;
		margin: 20px auto;
		input{

			width: 920px;
			height: 40px;
			background: #ffffff;
			border-radius: 4px;
			text-indent: 15px;

		}
	}

}
.form{

	width: 920px;
	margin:  0 auto;	
	.editor{
		height: 800px;
		textarea{
			margin-top: 30px;
			width: 920px;
		
			border: 1px solid #cccccc;
		}
		.ql-editor{
			height: 700px;
		}
	}
	.but{
		display: flex;
		justify-content: center;
		button{
			width: 240px;
			height: 45px;
			opacity: 1;
			border: 1px solid #cccccc;
			border-radius: 5px;
			text-align: center;
			line-height: 45px;
			margin:20px;
			color: #999;
		}
	}
	>p{
		color: #999999;
		text-align: center;
		margin-bottom: 50px;
		span{
			color: #FF4D51;
		}
	}
	.video{
		cursor: pointer;
		display: flex;
		align-items: center;
		justify-content: center;
		margin-top: 20px;
		width: 123px;
		height: 40px;
		opacity: 1;
		background: #ffffff;
		border: 1px solid #cccccc;
		border-radius: 5px;
		p{
			line-height: 40px;
			margin: 0px;
			margin-left: 10px;
		
		}
		
	}

}

</style>
<style>
  .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409EFF;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width:300px;
    height: 150px;
    line-height:150px;
    text-align: center;
  }
  .avatar {
    width: 300px;
    height: 150px;
    display: block;
  }
  .ql-editor{
			height: 700px;
		}
</style>
<style>
  .editor {
    line-height: normal !important;
    height: 800px;
  }
  .ql-snow .ql-tooltip[data-mode=link]::before {
    content: "请输入链接地址:";
  }
  .ql-snow .ql-tooltip.ql-editing a.ql-action::after {
      border-right: 0px;
      content: '保存';
      padding-right: 0px;
  }
 
  .ql-snow .ql-tooltip[data-mode=video]::before {
      content: "请输入视频地址:";
  }
 
  .ql-snow .ql-picker.ql-size .ql-picker-label::before,
  .ql-snow .ql-picker.ql-size .ql-picker-item::before {
    content: '14px';
  }
  .ql-snow .ql-picker.ql-size .ql-picker-label[data-value=small]::before,
  .ql-snow .ql-picker.ql-size .ql-picker-item[data-value=small]::before {
    content: '10px';
  }
  .ql-snow .ql-picker.ql-size .ql-picker-label[data-value=large]::before,
  .ql-snow .ql-picker.ql-size .ql-picker-item[data-value=large]::before {
    content: '18px';
  }
  .ql-snow .ql-picker.ql-size .ql-picker-label[data-value=huge]::before,
  .ql-snow .ql-picker.ql-size .ql-picker-item[data-value=huge]::before {
    content: '32px';
  }
 
  .ql-snow .ql-picker.ql-header .ql-picker-label::before,
  .ql-snow .ql-picker.ql-header .ql-picker-item::before {
    content: '文本';
  }
  .ql-snow .ql-picker.ql-header .ql-picker-label[data-value="1"]::before,
  .ql-snow .ql-picker.ql-header .ql-picker-item[data-value="1"]::before {
    content: '标题1';
  }
  .ql-snow .ql-picker.ql-header .ql-picker-label[data-value="2"]::before,
  .ql-snow .ql-picker.ql-header .ql-picker-item[data-value="2"]::before {
    content: '标题2';
  }
  .ql-snow .ql-picker.ql-header .ql-picker-label[data-value="3"]::before,
  .ql-snow .ql-picker.ql-header .ql-picker-item[data-value="3"]::before {
    content: '标题3';
  }
  .ql-snow .ql-picker.ql-header .ql-picker-label[data-value="4"]::before,
  .ql-snow .ql-picker.ql-header .ql-picker-item[data-value="4"]::before {
    content: '标题4';
  }
  .ql-snow .ql-picker.ql-header .ql-picker-label[data-value="5"]::before,
  .ql-snow .ql-picker.ql-header .ql-picker-item[data-value="5"]::before {
    content: '标题5';
  }
  .ql-snow .ql-picker.ql-header .ql-picker-label[data-value="6"]::before,
  .ql-snow .ql-picker.ql-header .ql-picker-item[data-value="6"]::before {
    content: '标题6';
  }
 
  .ql-snow .ql-picker.ql-font .ql-picker-label::before,
  .ql-snow .ql-picker.ql-font .ql-picker-item::before {
    content: '标准字体';
  }
  .ql-snow .ql-picker.ql-font .ql-picker-label[data-value=serif]::before,
  .ql-snow .ql-picker.ql-font .ql-picker-item[data-value=serif]::before {
    content: '衬线字体';
  }
  .ql-snow .ql-picker.ql-font .ql-picker-label[data-value=monospace]::before,
  .ql-snow .ql-picker.ql-font .ql-picker-item[data-value=monospace]::before {
    content: '等宽字体';
  }
  </style>